<template>
<div>

    <v-row align="center" class=" mb-3">
        <v-col cols="12" md="2" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-add`)|| $store.state.user.userType.id == $global.state.roleType.admin">
            <v-btn @click="openAddDialog()" color="primary" large class="btn">
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3>إضافة {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="3">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="searchName" label="بحث" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>
    </v-row>

    <v-card outlined>
        <v-data-table :items-per-page="items.length" :items="items" :headers="headers" :loading="loading" hide-default-footer no-data-text="لا توجد بيانات" loading-text="جاري تحميل البيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">
            <template v-slot:item.ordersCount="{item}">
                <v-chip color="grey">
                    {{ item.ordersCount }}
                </v-chip>
            </template>
            <template v-slot:item.lockedAt="{item}">
                <div v-if="item.lockedAt == null">
                    --:---:---
                </div>
                <div v-else>
                    {{item.lockedAt.substr(0,10)}}
                    <br />
                    <span class="grey--text" dir="ltr">{{ formatDate(item.lockedAt) }}</span>
                </div>
            </template>
            <template v-slot:item.created="{item}">
                <div v-if="item.created == null">
                    --:---:---
                </div>
                <div v-else>
                    {{item.created.substr(0,10)}}
                    <br />
                    <span class="grey--text" dir="ltr">{{ formatDate(item.created) }}</span>
                </div>
            </template>
            
            <template v-slot:item.isLocked="{item}" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-lock`)|| $store.state.user.userType.id == $global.state.roleType.admin">
                
                <v-chip color="error" v-if="item.isLocked == true" dark>
                    الوجبة مغلقة
                </v-chip>
                <v-chip color="teal" v-if="item.isLocked == false" dark>
                    الوجبة مفتوحة
                </v-chip>
            </template>
            <template v-slot:item.actions="{item}">
                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="lock(item.id)" v-on="on" icon :disabled="item.isLocked == true">
                            <v-icon color="error">
                                fi fi-rr-lock
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>قفل الوجبة</span>
                </v-tooltip>

                <v-tooltip bottom=""  v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-print`)|| $store.state.user.userType.id == $global.state.roleType.admin">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="print(item)" v-on="on" icon :disabled="item.ordersCount == 0">
                            <v-icon color="teal">
                                fi fi-rr-print
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>طباعة الوجبة</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <vue-easy-print class="print-only" ref="easyPrint" >
            <v-app>
                <Print :currentItem="selectedItem" :logo="$config.logo"/>
            </v-app> 
        </vue-easy-print>
    </v-card>
    <Pagination />
    <Dialog />
</div>
</template>
 
<style scoped>
@media print {
    @page {
        size: A4 landscape;
    }

    body {
        width: 297mm; /* A4 width */
        height: 210mm; /* A4 height */
        margin: 0;
    }

    .print-only {
        display: block;
    }

    

    /* Additional print styles if needed */
}
</style>

<script>
import moment from 'moment';
import vueEasyPrint from "vue-easy-print"; 
// import Print from "./print.vue";

export default {

    components: {
        Dialog: () => import("./dialog"),
        vueEasyPrint,
        Print: () => import("./print.vue") 
    },

    data() {
        return {
            loading: false,
            searchName: "",
            selectedItem: "",
            governorateId: "",
            headers: [{
                    text: "رقم الحزمة",
                    value: "no"
                },
                {
                    text: "اسم المندوب",
                    value: "agent.fullName"
                },
                {
                    text: "عدد الطلبات",
                    value: "ordersCount"
                },
                {
                    text: "حالة الوجبة",
                    value: "isLocked"
                },
                {
                    text: "تاريخ القفل",
                    value: "lockedAt"
                },
                {
                    text: "تاريخ الانشاء",
                    value: "created"
                },
                {
                    text: "",
                    value: "actions",
                    width: 100
                }
            ],

            items: []
        }
    },

    created() {
        this.getItems()
        setTimeout(() => {
            this.$eventBus.$on(`${this.$route.path}`, () => {
                this.getItems()
            })
        }, 1);
    },

    beforeDestroy() {
        this.$eventBus.$off(`${this.$route.path}`)
    },

    methods: {
        print(item){ 
            console.log(item)
            this.selectedItem = item
            setTimeout(() => {
                this.$refs.easyPrint.print()
            }, 1000);
        },
        formatDate(date) {
            return moment(date).format('h:mm:ss a');
        },

        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`Batch?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}`)
                this.items = res.data.result
                this.$global.state.count = res.data.count
                this.$global.state.length = res.data.count == undefined ? 0 : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async deleteItem(id) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `تم الحذف بنجاح`,
                                type: "success",
                            });
                            this.getItems();
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `حصل خطا اثناء الحذف`,
                                type: "error",
                            });
                        });
                }
            });
        },

        async lock(id) {
            console.log('iddddddddddddddddddddddddddd')
            console.log(id)
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`Batch/lock`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `تم قفل الوجبة بنجاح`,
                                type: "success",
                            });
                            this.getItems();
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `حصل خطا اثناء العملية`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: 'add'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
        },

        openEditDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fill-fields')
        },
    },

}
</script>
