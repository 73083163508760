var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:" mb-3",attrs:{"align":"center"}},[(_vm.$store.state.user.roles.find(function (x) { return x.key == ((_vm.$route.path.slice(1)) + "-add"); })|| _vm.$store.state.user.userType.id == _vm.$global.state.roleType.admin)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"btn",attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.openAddDialog()}}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v("add_circle_outline")]),_c('h3',[_vm._v("إضافة "+_vm._s(this.$route.meta.single))])],1)],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:500ms",value:(_vm.getItems),expression:"getItems",arg:"500ms"}],staticClass:"mr-1",attrs:{"label":"بحث","hide-details":"","prepend-inner-icon":"search","dense":"","outlined":"","filled":""},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items-per-page":_vm.items.length,"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"hide-default-footer":"","no-data-text":"لا توجد بيانات","loading-text":"جاري تحميل البيانات","id":_vm.$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'},scopedSlots:_vm._u([{key:"item.ordersCount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(item.ordersCount)+" ")])]}},{key:"item.lockedAt",fn:function(ref){
var item = ref.item;
return [(item.lockedAt == null)?_c('div',[_vm._v(" --:---:--- ")]):_c('div',[_vm._v(" "+_vm._s(item.lockedAt.substr(0,10))+" "),_c('br'),_c('span',{staticClass:"grey--text",attrs:{"dir":"ltr"}},[_vm._v(_vm._s(_vm.formatDate(item.lockedAt)))])])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [(item.created == null)?_c('div',[_vm._v(" --:---:--- ")]):_c('div',[_vm._v(" "+_vm._s(item.created.substr(0,10))+" "),_c('br'),_c('span',{staticClass:"grey--text",attrs:{"dir":"ltr"}},[_vm._v(_vm._s(_vm.formatDate(item.created)))])])]}},(_vm.$store.state.user.roles.find(function (x) { return x.key == ((_vm.$route.path.slice(1)) + "-lock"); })|| _vm.$store.state.user.userType.id == _vm.$global.state.roleType.admin)?{key:"item.isLocked",fn:function(ref){
var item = ref.item;
return [(item.isLocked == true)?_c('v-chip',{attrs:{"color":"error","dark":""}},[_vm._v(" الوجبة مغلقة ")]):_vm._e(),(item.isLocked == false)?_c('v-chip',{attrs:{"color":"teal","dark":""}},[_vm._v(" الوجبة مفتوحة ")]):_vm._e()]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":item.isLocked == true},on:{"click":function($event){return _vm.lock(item.id)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" fi fi-rr-lock ")])],1)]}}],null,true)},[_c('span',[_vm._v("قفل الوجبة")])]),(_vm.$store.state.user.roles.find(function (x) { return x.key == ((_vm.$route.path.slice(1)) + "-print"); })|| _vm.$store.state.user.userType.id == _vm.$global.state.roleType.admin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":item.ordersCount == 0},on:{"click":function($event){return _vm.print(item)}}},on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v(" fi fi-rr-print ")])],1)]}}],null,true)},[_c('span',[_vm._v("طباعة الوجبة")])]):_vm._e()]}}],null,true)}),_c('vue-easy-print',{ref:"easyPrint",staticClass:"print-only"},[_c('v-app',[_c('Print',{attrs:{"currentItem":_vm.selectedItem,"logo":_vm.$config.logo}})],1)],1)],1),_c('Pagination'),_c('Dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }